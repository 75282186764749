<!--
 * @Author: DY
 * @Date: 2020-08-15 14:27:02
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-01-05 16:50:34
 * @Describe:
-->
<template>
    <div class="comprehensiveControl">
        <back></back>
        <div class="header">
            <span>综合管控</span>
        </div>
        <div class="content">
            <ul class="list">
                <template v-for="(item,index) in listData">
                    <li :key="index" :class="{'active': selectList === index}" @click="typeSwitching(item,index)">
                        <div>{{ item.data }}</div>
                        <p>{{ item.name }}</p>
                    </li>
                </template>
            </ul>
            <div class="tableBox">
                <div class="tableHeader">
                    <div class="el_left">
                        <!--   <el-radio-group v-model="selectSite">-->
                        <!--       <el-radio v-for="item in tenantList" :label="item.zu_h_id" :key="item.zu_h_id">-->
                        <!--           {{ item.org_shortname }}-->
                        <!--       </el-radio>-->
                        <!--   </el-radio-group>-->
                        <el-select v-model="selectSite" placeholder="请选择">
                            <el-option
                                v-for="item in tenantList"
                                :key="item.zu_h_id"
                                :label="item.org_shortname"
                                :value="item.zu_h_id"
                            ></el-option>
                        </el-select>
                    </div>
                    <div class="el_right">
                        <el-input
                            placeholder="请输入内容"
                            suffix-icon="el-icon-search"
                            v-model="search"
                        >
                        </el-input>
                    </div>
                </div>
                <div class="tableContent">
                    <el-table
                        stripe
                        height="100%"
                        :data="tableData"
                        style="width: 100%"
                        v-scroll="scrollFn"
                    >
                        <template v-for="(item,index) in tableHeaderData">
                            <el-table-column
                                :key="index"
                                :prop="item.prop"
                                :label="item.name"
                                :width="item.width"
                                show-overflow-tooltip
                            >
                            </el-table-column>
                        </template>
                        <el-table-column
                            fixed="right"
                            label="管控通知"
                            width="80"
                            style="text-align:center;"
                            v-if="tableHeaderData.length>0"
                        >
                            <template slot-scope="scope">
                                <el-button
                                    @click.native.prevent="viewNotification(scope.row, listData[selectList].code)"
                                    type="text"
                                    size="small"
                                    style="color:#78D563;"
                                >
                                    【查看】
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <div class="chart" id="mapChart"></div>
        </div>
    </div>
</template>

<script>
import back from './../back';
export default {
    // 综合管控
    name: 'comprehensive-control',
    components: { back },
    directives: {
        scroll: {
            inserted: function (el, binding) {
                const cb = binding.value;
                const selectWrap = el.querySelector('.el-table__body-wrapper');
                selectWrap.addEventListener('mousewheel', function (e) {
                    const scrollDistance = this.scrollHeight - this.scrollTop - this.clientHeight;
                    const direction = e.deltaY > 0 ? 'down' : 'up';
                    cb(selectWrap, direction, scrollDistance);
                });
            },
        },
    },
    props: {},
    data() {
        return {
            // 类型数据
            listData: [
                {
                    name: '订单管控',
                    code: 'order_count',
                    data: 0,
                    data_qy: [],
                    prefix: 'sale',
                    tableUrl: 'comprehensive_control/order_list',
                    tableHeader: [{
                        name: '订单编号',
                        prop: 'order_number',
                        width: '180',
                    }, {
                        name: '合同编号',
                        prop: 'contract_number',
                        width: '180',
                    }, {
                        name: '场站',
                        prop: 'station_name',
                        width: '120',
                    }, {
                        name: '客户名称',
                        prop: 'customer_name',
                        width: '210',
                    }, {
                        name: '管控时间',
                        prop: 'control_start_time',
                        width: '200',
                    }, {
                        name: '状态',
                        prop: 'control_status',
                        width: '50',
                    }],
                },
                {
                    name: '超任务发货管控',
                    code: 'task_count',
                    data: 0,
                    data_qy: [],
                    prefix: 'sale',
                    tableUrl: 'comprehensive_control/task_list',
                    tableHeader: [ {
                        name: '任务单号',
                        prop: 'task_number',
                        width: '200',
                    }, {
                        name: '客户名称',
                        prop: 'customer_name',
                        width: '250',
                    }, {
                        name: '工程名称',
                        prop: 'project_name',
                        width: '200',
                    }, {
                        name: '管控时间',
                        prop: 'control_start_time',
                        width: '200',
                    }, {
                        name: '状态',
                        prop: 'control_status',
                        width: '50',
                    }],
                },
                {
                    name: '应收账款管控',
                    code: 'account_count',
                    data: 0,
                    data_qy: [],
                    prefix: 'sale',
                    tableUrl: 'comprehensive_control/account_list',
                    tableHeader: [ {
                        name: '合同编号',
                        prop: 'contract_number',
                        width: '200',
                    }, {
                        name: '客户名称',
                        prop: 'customer_name',
                        width: '250',
                    }, {
                        name: '工程名称',
                        prop: 'project_name',
                        width: '200',
                    }, {
                        name: '管控时间',
                        prop: 'control_start_time',
                        width: '200',
                    }, {
                        name: '状态',
                        prop: 'control_status',
                        width: '50',
                    }],
                },
                {
                    name: '最低限价预警',
                    code: '1',
                    data: 0,
                    data_qy: [],
                    prefix: '',
                    tableUrl: '',
                    tableHeader: [],
                },
                {
                    name: '试验配比异常预警',
                    code: 'experiment_count',
                    data: 0,
                    data_qy: [],
                    prefix: 'production',
                    tableUrl: 'comprehensive_control/experiment_list',
                    tableHeader: [ {
                        name: '异常配比管控编号',
                        prop: 'formula_control_code',
                        width: '200',
                    }, {
                        name: '试验配比编号',
                        prop: 'formula_number',
                        width: '200',
                    }, {
                        name: '异常配比内容',
                        prop: 'control_content',
                        width: '200',
                    }, {
                        name: '管控时间',
                        prop: 'control_start_time',
                        width: '200',
                    }],
                },
                {
                    name: '施工配比异常预警',
                    code: 'product_count',
                    data: 0,
                    data_qy: [],
                    prefix: 'production',
                    tableUrl: 'comprehensive_control/product_list',
                    tableHeader: [ {
                        name: '异常配比管控编号',
                        prop: 'formula_control_code',
                        width: '200',
                    }, {
                        name: '施工配比编号',
                        prop: 'formula_number',
                        width: '200',
                    }, {
                        name: '异常配比内容',
                        prop: 'control_content',
                        width: '200',
                    }, {
                        name: '管控时间',
                        prop: 'control_start_time',
                        width: '200',
                    }],
                },
                {
                    name: '原材料质量预警',
                    code: '1',
                    data: 0,
                    data_qy: [],
                    prefix: '',
                    tableUrl: '',
                    tableHeader: [],
                },
                {
                    name: '运输异常管控',
                    code: '1',
                    data: 0,
                    data_qy: [],
                    prefix: '',
                    tableUrl: '',
                    tableHeader: [],
                },
            ],
            // 选中类型下标
            selectList: 0,
            // 租户列表
            tenantList: [],
            // 选中站点租户id
            selectSite: '',
            // 查询条件
            search: '',
            // 列表表头数据
            tableHeaderData: [],
            // 列表数据
            tableData: [],
            // 列表每页条数
            limit: 8,
            // 列表当前页数
            page: 1,
            // 数据总条数
            total: 0,
            // 总页数
            pageCount: 0,
            // 是否是最后一页
            isLastPage: false,
        };
    },
    watch: {
        selectList() {
            // 列表表头数据
            this.tableHeaderData = this.listData[this.selectList].tableHeader;
            this.loadMapData(this.listData[this.selectList].data_qy);
            // 列表当前页数
            this.page = 1;
            this.isLastPage = false;
            // 列表数据
            this.tableData = [];
            if (this.listData[this.selectList].tableUrl !== '') {
                // 获取列表数据
                this.getTableData();
            }
        },
        selectSite() {
            // 列表当前页数
            this.page = 1;
            this.isLastPage = false;
            this.tableData = [];
            // 获取列表数据
            this.getTableData();
        },
        search(newVal) {
            if (newVal !== '') {
                // 列表当前页数
                this.page = 1;
                this.isLastPage = false;
                this.tableData = [];
                // 获取列表数据
                this.getTableData();
            }
        },
    },
    created() {
        this.initData();
    },
    mounted() {},
    computed: {},
    methods: {
        async initData() {
            await this.getEnterpriceList();
            // 列表表头数据
            this.tableHeaderData = this.listData[this.selectList].tableHeader;
            // 查询订单、超发任务管控条数
            this.getTaskData();
            // 查询试验、施工配比管控条数
            this.getControlData();
            // 获取列表数据
            this.getTableData();
        },
        // 类型切换
        typeSwitching(data, index) {
            this.selectList = index;
        },
        // 查询订单、超发任务管控条数
        getTaskData() {
            this.$axios
                .get('/interfaceApi/sale/comprehensive_control/count')
                .then(res => {
                    this.listData.forEach(item => {
                        if (item.code === 'order_count') {
                            item.data = res.order_count;
                            item.data_qy = res.order_count_qy;
                            // 加载地图数据
                            this.loadMapData(res.order_count_qy);
                        } else if (item.code === 'account_count') {
                            item.data = res.account_count;
                            item.data_qy = res.account_count_qy;
                        } else if (item.code === 'task_count') {
                            item.data = res.task_count;
                            item.data_qy = res.task_count_qy;
                        }
                    });
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 查询试验、施工配比管控条数
        getControlData() {
            this.$axios
                .get('/interfaceApi/production/comprehensive_control/count')
                .then(res => {
                    this.listData.forEach(item => {
                        if (item.code === 'experiment_count') {
                            item.data = res.experiment_count;
                            item.data_qy = res.experiment_count_qy;
                        } else if (item.code === 'product_count') {
                            item.data = res.product_count;
                            item.data_qy = res.product_count_qy;
                        }
                    });
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 获取列表数据
        getTableData() {
            this.$axios
                // eslint-disable-next-line max-len
                .get(`/interfaceApi/${this.listData[this.selectList].prefix}/${this.listData[this.selectList].tableUrl}/${this.page}/${this.limit}?search=${this.search}&zu_h_id=${this.selectSite}`)
                .then(res => {
                    this.tableData.push(...res.rows);
                    // 数据总条数
                    this.total = res.total;
                    // 总页数
                    this.pageCount = Math.ceil(this.total / this.limit);
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 鼠标滚动加载更多
        scrollFn: function (obj, direction, scrollTop) {
            if (direction === 'up' || this.loading === true || this.isLastPage === true) {return;}
            if (scrollTop <= 30) {
                if (this.tableData.length < this.total) {
                    this.page++;
                    this.getTableData();
                } else {
                    this.isLastPage = true;
                }
            }
        },
        // 查看通知
        viewNotification(row, code) {
            let queryString = '';
            let control_instance_code = '';
            if (code === 'order_count') {
                queryString = 'control_type=2&business_type=1&business_number=' + row.order_number;
            } else if (code === 'task_count') {
                queryString = 'control_type=2&business_type=2&business_number=' + row.task_number;
            } else if (code === 'experiment_count') {
                control_instance_code = 'TP2008290001';
            } else if (code === 'product_count') {
                control_instance_code = 'TP2008290002';
            }
            if (code === 'order_count' || code === 'task_count') {
                this.$toast({
                    title: true,
                    type: 'eject',
                    width: '13.4rem',
                    t_url: 'tableList/index',
                    extr: {
                        code: {
                            TableCode: 'control_instance_list',
                            QueryString: queryString,
                        },
                    },
                });
            } else if (code === 'account_count') {
                this.$toast({
                    title: false,
                    type: 'eject',
                    width: '9.4rem',
                    height: '6.6rem',
                    t_url: 'contractControl/earlyWarning',
                    extr: {
                        closeBtn: 'color: #333;',
                        control_instance_code: row.control_instance_code,
                        control_msg_code: row.control_msg_code,
                        tenant_Id: row.zu_h_id,
                    },
                });
            } else {
                this.$toast({
                    title: false,
                    type: 'eject',
                    width: '9.4rem',
                    height: '6.6rem',
                    t_url: 'contractControl/earlyWarning',
                    extr: {
                        closeBtn: 'color: #333;',
                        control_instance_code: control_instance_code,
                        control_msg_code: row.control_msg_code,
                        tenant_Id: row.zu_h_id,
                    },
                });
            }
        },
        // 加载地图数据
        loadMapData(order_count_qy) {
            this.drawMap('mapChart', order_count_qy);
        },
        // 地图
        drawMap(value, order_count_qy) {
            const _that = this;
            $.get('./../../mapJson/china.json', function (geoJson) {
                window.$globalHub.$echarts.registerMap('china', geoJson);
                const myChart = window.$globalHub.$echarts.init(document.getElementById(value));
                const geoCoordMap = {
                    // 东营: [118.674623, 37.434005],
                };
                let data = [];
                const orderCountMapping = {};
                order_count_qy.forEach(item => {
                    orderCountMapping[item.zu_h_id] = item.count;
                });
                _that.tenantList.forEach(item => {
                    geoCoordMap[item.zu_h_id] = [item.x_coordinate_point, item.y_coordinate_point];
                    const org_shortname = item.org_shortname || item.org_name;
                    // const shortName = org_shortname.replace(/商混|中联/, '');
                    data.push({
                        tenant_id: item.zu_h_id,
                        name: org_shortname,
                        transAmt: orderCountMapping[item.zu_h_id] || 0,
                    });
                });
                // 过滤掉 count 为0 的数据
                data = data.filter(item => item.transAmt !== 0);
                const convertData = function (data) {
                    const res = [];
                    for (let i = 0; i < data.length; i++) {
                        const geoCoord = geoCoordMap[data[i].tenant_id];
                        if (geoCoord) {
                            res.push({
                                tenant_id: data[i].tenant_id,
                                name: data[i].name,
                                value: geoCoord.concat(data[i].transAmt),
                            });
                        }
                    }
                    return res;
                };

                const mapOption = {
                    visualMap: {
                        show: false,
                        left: 'right',
                        min: 100,
                        max: 3800,
                        inRange: {
                            color: ['#016ABB', '#014FB1', '#2536AA', '#0055B0'],
                        },
                        text: ['High', 'Low'], // 文本，默认为数值文本
                        calculable: true,
                        seriesIndex: [0],
                    },
                    geo: {
                        name: '1',
                        type: 'map',
                        map: 'china', // 类型,与上文中注册地图的名称一致
                        zoom: 3, // 当前视角的缩放比例
                        roam: true, // 是否开启平游或缩放
                        label: {
                            normal: {
                                show: true,
                                color: '#04B3F4', // 每个区域文字颜色
                                fontSize: '16px',
                            },
                            emphasis: {
                                show: true,
                            },
                        },
                        mapLocation: {
                            y: 60,
                        },
                        itemStyle: {
                            emphasis: {
                                label: {
                                    show: false,
                                },
                            },
                            normal: {
                                label: {
                                    show: !0,
                                },
                                formatter: function formatter(a) {
                                    return a.value;
                                },
                                borderColor: '#063E71', // 每个区域的边框色
                                areaColor: '#011837', // 区域背景色
                            },

                        },
                    },
                    series: [{
                        type: 'map',
                        map: 'china',
                        geoIndex: 0,
                        aspectScale: 0.75, // 长宽比
                        showLegendSymbol: false, // 存在legend时显示
                        label: {
                            normal: {
                                show: true,
                                color: '#04B3F4', // 每个区域文字颜色
                                fontSize: '16px',
                            },
                            emphasis: {
                                show: true,
                            },
                        },
                        mapLocation: {
                            y: 60,
                        },
                        itemStyle: {
                            emphasis: {
                                label: {
                                    show: false,
                                },
                            },
                            normal: {
                                label: {
                                    show: !0,
                                },
                                formatter: function formatter(a) {
                                    return a.value;
                                },
                                borderColor: '#063E71', // 每个区域的边框色
                                areaColor: '#011837', // 区域背景色
                            },

                        },
                        animation: false,
                        data: [
                            {
                                name: '北京',
                                value: 125,
                            },
                            {
                                name: '河北',
                                value: 125,
                            },
                            {
                                name: '山东',
                                value: 125,
                            },
                            {
                                name: '河南',
                                value: 125,
                            },
                            {
                                name: '安徽',
                                value: 125,
                            },
                            {
                                name: '江苏',
                                value: 125,
                            },
                        ],
                    }, {

                        symbolSize: 5,
                        label: {
                            normal: {
                                formatter: '{b}',
                                position: 'right',
                                show: true,
                            },
                            emphasis: {
                                show: true,
                            },
                        },
                        itemStyle: {
                            normal: {
                                color: '#fff',
                            },
                        },
                        name: 'light',
                        type: 'scatter',
                        coordinateSystem: 'geo',
                        data: convertData(data),

                    }, {
                        type: 'map',
                        map: 'china',
                        geoIndex: 0,
                        aspectScale: 0.75, // 长宽比
                        showLegendSymbol: false, // 存在legend时显示
                        label: {
                            normal: {
                                show: false,
                            },
                            emphasis: {
                                show: false,
                                textStyle: {
                                    color: '#fff',
                                },
                            },
                        },
                        roam: true,
                        itemStyle: {
                            normal: {
                                areaColor: '#031525',
                                borderColor: '#FFFFFF',
                            },
                            emphasis: {
                                areaColor: '#2B91B7',
                            },
                        },
                        animation: false,
                        data: data,
                    }, {
                        name: 'Top 5',
                        type: 'scatter',
                        coordinateSystem: 'geo',
                        symbol: 'pin',
                        symbolSize: [50, 50],
                        label: {
                            normal: {
                                show: true,
                                textStyle: {
                                    color: '#00392C',
                                    fontSize: 20,
                                    fontFamily: 'technology',
                                },
                                formatter(value) {
                                    return value.data.value[2];
                                },
                            },
                        },
                        itemStyle: {
                            normal: {
                                color: '#5EC875', // 标志颜色
                            },
                        },
                        data: convertData(data),
                        showEffectOn: 'render',
                        rippleEffect: {
                            brushType: 'stroke',
                        },
                        hoverAnimation: true,
                        zlevel: 1,
                    }],
                };
                myChart.setOption(mapOption);
                myChart.resize();
                myChart.on('click', function (params) {
                    _that.selectSite = params.data.tenant_id;
                });
            });
        },
        // 获取上线企业列表
        async getEnterpriceList(org_id = '') {
            const path = '/interfaceApi/report/leadercockpit/getorgdata?org_id=' + org_id;
            const data = await this.$axios.$get(path, { defEx: true }) || [];
            data.forEach(item => {
                item.org_shortname = (item.org_shortname || item.org_name).replace(/商混|中联/, '');
            });
            this.tenantList = data;
            if (this.tenantList.length > 0) {
                this.selectSite = this.tenantList[0].zu_h_id;
            }
        },
    },
};
</script>
<style lang="stylus">
.popupHeader
    background #257AD6
    background-size 100% 100%
.el-select-dropdown
    border 0;
    background: rgb(2, 50, 115);
    .popper__arrow:after
        background: rgb(2, 50, 115);
    .el-select-dropdown__item
        color rgba(255, 255, 255, 0.8)
        &.selected
            color: #409EFF;
        &:hover,&.hover
            background-color: #001F49;
.comprehensiveControl
    width 100%
    height 100%
    overflow hidden
    background #0B204B
    position relative
    .header
        height 0.61rem
        text-align center
        background url(images/bg_header.png) center no-repeat
        span
            font-size 0.24rem
            font-family Source Han Sans CN
            font-weight bold
            color rgba(255, 255, 255, 1)
            line-height 0.5rem
            background linear-gradient(0deg, rgba(108, 151, 216, 1) 0%, rgba(255, 255, 255, 1) 100%)
            -webkit-background-clip text
            -webkit-text-fill-color transparent
    .content
        height calc(100% - 0.61rem)
        position relative
        .list
            width 10rem
            position absolute
            top 0.5rem
            left 0.8rem
            z-index 9
            li
                float left
                margin 0.1rem 0.2rem
                width 2rem
                text-align center
                -webkit-user-select:none;
                -moz-user-select:none;
                -ms-user-select:none;
                user-select:none;
                div,p
                    display inline-block
                div
                    width 1.6rem
                    height 1.6rem
                    font-size 0.36rem
                    line-height 1.5rem
                    color #fff
                    text-align center
                    font-family technology
                    background-position center
                    background-repeat no-repeat
                 p
                    text-align center
                    font-size 0.16rem
                    line-height 0.36rem
                    color rgba(255,255,255,0.8)
                &:nth-of-type(1)
                    div
                        background-image url(images/btn_1.png)
                &:nth-of-type(2)
                    div
                        background-image url(images/btn_2.png)
                &:nth-of-type(3)
                    div
                        background-image url(images/btn_3.png)
                &:nth-of-type(4)
                    div
                        background-image url(images/btn_4.png)
                &:nth-of-type(5)
                    div
                        background-image url(images/btn_5.png)
                &:nth-of-type(6)
                    div
                        background-image url(images/btn_6.png)
                &:nth-of-type(7)
                    div
                        background-image url(images/btn_7.png)
                &:nth-of-type(8)
                    div
                        background-image url(images/btn_8.png)
                &.active
                &:hover
                    div
                        color #00FFFF
                        font-size 0.7rem
                        line-height 1rem
                        background-image url(images/btn_highlight.png)
                    p
                        font-size 0.24rem
                        color #00FFFF
        .tableBox
            width 9rem
            height 3.4rem
            position absolute
            left 1rem
            top 5rem
            z-index 9
            background #002557
            .tableHeader
                height 0.5rem
                display flex
                justify-content space-between
                align-items center
                .el_left
                    max-width 4.6rem;
                    .el-radio-group
                        padding 0.1rem;
                        .el-radio
                            color rgba(255,255,255,0.5)
                            margin 0.05rem 0.1rem;
                    .el-select
                        .el-option
                            color rgba(255,255,255,0.5)
                            margin 0.05rem 0.1rem;
                        .el-input
                            width 2rem
                            margin 0.07rem
                            .el-input__inner
                                height 0.36rem
                                line-height 0.36rem
                                border 1px solid #023273
                                background #001F49
                                color rgba(255,255,255,0.8)
                .el_right
                    .el-input
                        margin-right 0.15rem
                        width 4.2rem
                        margin 0.07rem
                        .el-input__inner
                            height 0.36rem
                            line-height 0.36rem
                            border 1px solid #023273
                            background #001F49
                            color rgba(255,255,255,0.8)
            .tableContent
                height calc(100% - 0.5rem)
                .el-table
                    background #002557
                    &:before
                        display none
                    tr
                        background #002557
                    th,td
                        padding 0
                        height 0.34rem
                        font-size 0.14rem
                        line-height 0.34rem
                        text-align center
                        border none
                        .cell
                            line-height 0.34rem
                    th
                        background #0D53A1
                        color rgba(255,255,255,0.8)
                    td
                        color #00C3FF
                        background rgba(15, 68, 127, 0.4)
                    .el-table__row--striped
                        td
                            background rgba(15, 68, 127, 0.6)
                    .el-table__fixed-right-patch
                        background #0D53A1
                        border none
                        top 0
                    .el-table__fixed-right
                        &:before
                            display none
                    .el-table__body-wrapper
                        height calc(100% - 0.34rem) !important
                        &::-webkit-scrollbar
                            width 10px
                            height 10px
                        &::-webkit-scrollbar-thumb
                            border-radius 10px
                            background linear-gradient(to left, #0F3263 0%, #426EAA 100%)
                        &::-webkit-scrollbar-track
                            -webkit-box-shadow inset 0 0 5px #083775
                            border-radius 0
                            background #002557
        .chart
            height 100%
            .mapTooltip
                width 3.36rem
                height 2.04rem
                background url('images/mapTooltipBg.png') center no-repeat
                background-size: 100% 100%
                h4
                    font-size: 0.16rem
                    color #ffffff
                    text-align: center
                    line-height: 0.52rem
                i
                    display: block
                    width 100%
                    height 0.02rem
                    background url('images/mapTooltipIcon.png') center no-repeat
                    background-size: 100% 100%
                table
                    width 100%
                    margin-top: 0.15rem
                    td
                        height 0.6rem
                        text-align: left
                        text-indent: 0.3rem
                        h5
                            font-size: 0.12rem
                            margin-bottom: 0.04rem
                        p
                            font-size: 0.24rem
                            font-family: technology
                        &.amount
                            color #26BFFA
                        &.price
                            color #FA8436
                        &.paragraph
                            color #2096F3
                        &.book
                            color #FC6B51
                        &.benefit
                            color #36FAC0
</style>
